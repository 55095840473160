// react + dependencies
import { Navigate, Route, Routes } from "react-router-dom";
import TagManager from "react-gtm-module";
// pages
import Home from "./pages/Home";

const tagManagerArgs = {
  gtmId: "GTM-????",
};

TagManager.initialize(tagManagerArgs);

function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </>
  );
}

export default App;
