import React from 'react'

const Home = () => {
    return (
        <section className='h-screen bg-emerald-50 '>
            <div className='h-full col-center justify-center'>
                <h1 className='uppercase leading-8 text-4xl'>běhám a jím</h1>
            </div>
        </section>
    )
}

export default Home